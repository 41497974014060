import { EventStream } from 'Roblox';
import { authenticatedUser } from 'header-scripts';
import groupsModule from '../groupsModule';

function verificationService($q, $filter, httpService, verificationResources, groupsConstants) {
  'ngInject';

  return {
    is2SVEnabled() {
      return $q((resolve, reject) => {
        httpService
          .httpGet({
            url: $filter('formatString')(groupsConstants.urls.get2SVConfiguration, {
              userId: authenticatedUser?.id
            })
          })
          .then(result => {
            const enabled = result.methods.some(
              method =>
                method.mediaType === groupsConstants.twoStepMediaType.email && method.enabled
            );
            resolve(enabled);
          })
          .catch(() => {
            reject(false);
          });
      });
    },

    generateChallengeCode() {
      return $q((resolve, reject) => {
        httpService
          .httpPost({
            url: groupsConstants.urls.generateChallenge
          })
          .then(challengeId => {
            resolve(challengeId);
          })
          .catch(error => {
            const errorCode = httpService.getPrimaryApiErrorCode(
              error,
              verificationResources.errorCodes.api
            );
            reject({
              code: errorCode,
              message: error.message
            });
          });
      });
    },

    verifyChallengeCode(userId, challengeId, code) {
      return $q((resolve, reject) => {
        httpService
          .httpPost(
            {
              url: $filter('formatString')(groupsConstants.urls.verifyChallenge, { userId })
            },
            {
              challengeId,
              actionType: verificationResources.actionTypes.robuxSpend,
              code
            }
          )
          .then(data => {
            resolve(data.verificationToken);
          })
          .catch(error => {
            const errorCode = httpService.getPrimaryApiErrorCode(
              error,
              verificationResources.errorCodes.api
            );
            reject({
              code: errorCode,
              message: error.message
            });
          });
      });
    },

    redeemVerificationChallenge(challengeToken, verificationToken) {
      return $q((resolve, reject) => {
        httpService
          .httpPost(
            {
              url: groupsConstants.urls.redeemChallenge
            },
            {
              challengeToken,
              verificationToken
            }
          )
          .then(result => {
            resolve(result);
          })
          .catch(error => {
            const errorCode = httpService.getPrimaryApiErrorCode(
              error,
              verificationResources.errorCodes.api
            );
            reject({
              code: errorCode,
              message: error.message
            });
          });
      });
    },

    resendCode(userId, challengeId) {
      return $q((resolve, reject) => {
        httpService
          .httpPost(
            {
              url: $filter('formatString')(groupsConstants.urls.resendCode, { userId })
            },
            {
              challengeId,
              actionType: verificationResources.actionTypes.robuxSpend
            }
          )
          .then(() => {
            resolve({});
          })
          .catch(error => {
            const errorCode = httpService.getPrimaryApiErrorCode(
              error,
              verificationResources.errorCodes.api
            );
            reject({
              code: errorCode,
              message: error.message
            });
          });
      });
    },

    sendFrictionEvent(buttonName) {
      if (EventStream && EventStream.SendEvent) {
        EventStream.SendEvent(
          verificationResources.events.frictionEventType,
          verificationResources.events.twoStepVerificationCtx,
          { btn: buttonName }
        );
      }
    }
  };
}

groupsModule.factory('verificationService', verificationService);

export default verificationService;
