import { func } from 'prop-types';
import groupListModule from '../groupListModule';

function groupsListService(httpService, $filter, $q, $timeout, $rootScope, groupsListConstants) {
    "ngInject";

    function getGroups(userId) {
        return $q(function (resolve, reject) {
            var config = {
                url: $filter("formatString")(groupsListConstants.urls.groupsListUrl, { id: userId }),
                retryable: false
            }
            httpService.httpGet(config, {}).then(
                (responseData) => {
                    if (responseData?.data) {
                        processGroups(responseData.data).then(
                            (groups) => {
                                getPrimaryGroup(userId).then(
                                    (responseData) => {
                                        if (responseData?.group) {
                                            for (var i = 0; i < groups.length; i++) {
                                                var group = groups[i];
                                                if (group.id === responseData.group.id) {
                                                    group.isPrimary = true;
                                                    groups.splice(i, 1);
                                                    groups.unshift(group);
                                                    break;
                                                }
                                            }
                                        }
                                        resolve(groups);
                                    }, () => {
                                        // Groups Api returns a 404 if the user doesn't have a primary, but we don't need to do anything as a result.
                                        resolve(groups);
                                    }
                                );
                            }
                        );
                    }
                }, () => {
                    httpService.getApiErrorCodeHandler(reject, groupsListConstants.errorCodes.getGroups);
                }
            );
        });
    };

    const processGroups = (data) =>  {
        if (data) {
            return getVisibleGroups(data).then(
                (groups) => {
                    const { ownerRoleRank } = groupsListConstants;
                    var mappedGroups = groups.map(translateResultToGroup);
                    mappedGroups.sort((a, b) => {
                        if (b.role.rank === ownerRoleRank && a.role.rank === ownerRoleRank) {
                            return a.name.localeCompare(b.name, { sensitivity: "base" });
                        } else if (a.role.rank == ownerRoleRank) {
                            return -1;
                        } else if (b.role.rank === ownerRoleRank) {
                            return 1;
                        }
                        return a.name.localeCompare(b.name, { sensitivity: "base" });
                    });
                    return mappedGroups;
                },
                () => {
                   return [];
                }
            );
        }
    };

    const getVisibleGroups = (data) => {
        let groupIds = [];
        let groupsDict = {};
        for (let i = 0; i < data.length; i++) {
            groupsDict[data[i].group.id] = data[i];
            groupIds.push(data[i].group.id);
        }
        
        return getGroupPolicyInfo(groupIds).then(
            (result) => {
                let visibleGroups = [];
                if (result?.groups) {
                    var groupPolicies = result.groups;
                    for (let i = 0; i < groupPolicies.length; i++) {
                        // FIXME: should I have the second check?
                        if (groupPolicies[i].canViewGroup && groupsDict[groupPolicies[i].groupId]){
                            visibleGroups.push(groupsDict[groupPolicies[i].groupId]);
                        }
                    }
                }
                return visibleGroups;
            }, 
            () => {
                $log.debug('--getGroupPolicies-error--');
                // FIXME: should I throw the error instead? Can I log it better? Is
                // it auto-logged?
                return [];
            }
        );
    };
    
    const translateResultToGroup = (result) => {
        return {
            id: result.group.id,
            name: result.group.name,
            description: result.group.description,
            members: result.group.memberCount,
            role: result.role,
            isPrimary: false,
            isOwner: result.role.rank === groupsListConstants.ownerRoleRank,
            groupUrl: $filter("seoUrl")("groups", result.group.id, result.group.name)
        }
    };

    const getPrimaryGroup = (userId) => {
        var config = {
            url: $filter("formatString")(groupsListConstants.urls.primaryGroupUrl, { id: userId })
        }
        return httpService.httpGet(config);
    };

    
    const getGroupPolicyInfo = (groupIds) => {
        const config = {
          url: $filter('formatString')(groupsListConstants.urls.getGroupPolicyInfo)
        };
  
        const request = {
          groupIds
        };
  
        return httpService.httpPost(config, request);
    }

    const buildScrollbar = (className) => {
        var scrollbarElm = angular.element(document.querySelector(className));
        scrollbarElm.mCustomScrollbar({
            autoExpandScrollbar: false,
            scrollInertia: 500,
            contentTouchScroll: 1,
            mouseWheel: {
                preventDefault: true,
                scrollAmount: 208,
                deltaFactor: 208
            }
        });
    };

    const lazyImageRefresh = () => {
        $timeout(() => {
            $rootScope.$emit('lazyImg:refresh');
        });
    }

    return {
        getGroups: getGroups,
        getPrimaryGroup: getPrimaryGroup,
        buildScrollbar: buildScrollbar,
        lazyImageRefresh: lazyImageRefresh
    };
}

groupListModule.factory("groupsListService", groupsListService);

export default groupsListService;