import { DisplayNames } from 'Roblox';
import groupsModule from '../groupsModule';

function groupsService($q, httpService, groupsConstants, $filter) {
  'ngInject';

  let configurationMetadata;

  return {
    getGroup(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroup, { id: groupId })
      };

      return httpService.httpGet(config, {}).then(result => {
        if (result?.owner) {
          const currentUser = result.owner;
          const { displayName, username } = currentUser;
          currentUser.nameForDisplay = DisplayNames.Enabled() ? displayName : username;
        }
        return result;
      });
    },

    getGroupMetadata() {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupMetadata)
      };

      return httpService.httpGet(config);
    },

    getGroupConfigurationMetadata() {
      return $q(function (resolve, reject) {
        if (configurationMetadata) {
          resolve(configurationMetadata);
          return;
        }

        const config = {
          url: $filter('formatString')(groupsConstants.urls.getGroupConfigurationMetadata)
        };

        httpService.httpGet(config).then(function (response) {
          configurationMetadata = response;
          resolve(response);
        }, reject);
      });
    },

    getGroupRoles(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupRoles, { id: groupId }),
        retryable: false
      };

      return httpService.httpGet(config);
    },

    getGroupRolePermissions(groupId, roleSetId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupRolePermissions, {
          groupId,
          roleSetId
        })
      };

      return httpService.httpGet(config, {});
    },

    exileUser(groupId, userId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.updateUserRole, {
          groupId,
          userId
        })
      };

      return httpService.httpDelete(config);
    },

    deleteWallPostsByUser(groupId, userId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.deleteWallPostsByUser, {
          groupId,
          userId
        })
      };

      return httpService.httpDelete(config);
    },

    getGroupSettings(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.updateGroupSettings, { id: groupId })
      };

      return httpService.httpGet(config, {});
    },

    updateGroupSettings(groupId, updateGroupSettingsRequest) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.updateGroupSettings, { id: groupId })
      };

      return httpService.httpPatch(config, updateGroupSettingsRequest);
    },

    getUserIdsFromUsernames(usernames, excludeBannedUsers = false) {
      return $q(function (resolve, reject) {
        const config = {
          url: $filter('formatString')(groupsConstants.urls.usernames)
        };

        const request = {
          usernames,
          excludeBannedUsers
        };

        httpService.httpPost(config, request).then(
          function (result) {
            resolve(result.data);
          },
          function (error) {
            reject(error);
          }
        );
      });
    },

    getUserRoleInGroup(userId, groupId) {
      return $q(function (resolve, reject) {
        const config = {
          url: $filter('formatString')(groupsConstants.urls.getGroupRolesForUser, {
            userId
          })
        };

        httpService.httpGet(config).then(
          function (result) {
            if (result.data) {
              const currentGroup = result.data.filter(function (groupData) {
                return groupData.group.id === groupId;
              });
              if (currentGroup && currentGroup.length > 0) {
                resolve(currentGroup[0].role);
              } else {
                resolve(null);
              }
            }
          },
          function () {
            reject('Could not fetch user groups. Please try again.');
          }
        );
      });
    },

    getGroupDetailRules() {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupDetailsPolicy)
      };

      return httpService.httpGet(config);
    },

    getConfigureGroupRules() {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getConfigureGroupPolicy)
      };

      return httpService.httpGet(config);
    },

    getGroupPolicyInfo(groupIds) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupPolicyInfo)
      };

      const request = {
        groupIds
      };

      return httpService.httpPost(config, request);
    },

    getGroupCurrency(groupId) {
      const urlConfig = {
        url: $filter('formatString')(groupsConstants.urls.getCurrency, { groupId })
      };

      return $q(function (resolve, reject) {
        return httpService.httpGet(urlConfig).then(
          function (responseBody) {
            resolve(responseBody.robux);
          },
          function (responseBody) {
            const errorCodes = httpService.getApiErrorCodes(responseBody);
            reject(errorCodes[0] || 0);
          }
        );
      });
    },

    getUserCurrency(userId) {
      const urlConfig = {
        url: $filter('formatString')(groupsConstants.urls.getUserCurrency, { userId })
      };

      return $q(function (resolve, reject) {
        return httpService.httpGet(urlConfig).then(
          function (responseBody) {
            resolve(responseBody.robux);
          },
          function (responseBody) {
            const errorCodes = httpService.getApiErrorCodes(responseBody);
            reject(errorCodes[0] || 0);
          }
        );
      });
    },

    getAddFundsAllowed(groupId) {
      var urlConfig = {
        url: $filter("formatString")(groupsConstants.urls.getAddFundsAllowedUrl, { groupId: groupId })
      };

      // Always resolve
      return $q(function (resolve, reject) {
        return httpService.httpGet(urlConfig).then(
          function (canAddFunds) {
            resolve(canAddFunds);
          },
          function () {
            resolve(false)
          }
        );
      });
    },
  };
}

groupsModule.factory('groupsService', groupsService);

export default groupsService;
