import { Lang } from 'Roblox';
import angular from 'angular';

const groupList = angular
  .module('groupList', [
    'robloxApp',
    'groups',
    'groupsListAppHtmlTemplateApp',
    'ui.router',
    'thumbnails'
  ])
  .config([
    'languageResourceProvider',
    function (languageResourceProvider) {
      // Temporary until translations added
      const resource = Lang.GroupsResources;
      const translationConstants = {
        'Label.MembersCount':
          (resource && resource['Label.MembersCount']) ||
          '{memberCount, plural, =0 {# Members} =1 {# Member} other {# Members}}'
      };

      if (Lang.GroupsResources) {
        languageResourceProvider.setLanguageKeysFromFile(Lang.GroupsResources);
        languageResourceProvider.setLanguageKeysFromFile(translationConstants);
      }
    }
  ]);

export default groupList;
