import groupListModule from "../groupListModule";
import { CurrentUser } from "Roblox";

function groupsListBaseController($scope, $q, groupsListService, groupsService, $log, groupsListConstants, groupsConstants) {
    "ngInject";

    $scope.createGroupUrl = function () {
        return groupsConstants.absoluteUrls.createGroup;
    };

    $scope.canCreateGroup = function () {
        return !$scope.groups || $scope.metadata.currentGroupCount < $scope.metadata.groupLimit;
    };

    $scope.loadGroups = function () {
        return $q(function (resolve, reject) {
            groupsListService.getGroups($scope.userId).then(
                function (result) {
                    if (result) {
                        $scope.groups = result;
                    }
                    resolve(result);
                }, function (data) {
                    $scope.groups = [];
                    $scope.layout.loadFailure = true;
                    $log.debug("--loadGroups-error---");
                    reject(data);
                }
            );
        });
    };

    $scope.loadGroupMetadata = function () {
        return $q(function (resolve, reject) {
            groupsService.getGroupMetadata().then(
                function (result) {
                    $scope.metadata = result;
                    resolve(result);
                }, function (data) {
                    // Don't need to consider this a load failure, list is functional without
                    $log.debug("--loadGroupMetadata-error---");
                    reject(data);
                }
            );
        });
    };

    $scope.updateDisplay = function (showGrid) {
        $scope.layout.isGridOn = showGrid;
        groupsListService.lazyImageRefresh();
    };

    function initVariables() {
        $scope.layout = groupsListConstants.layout;

        $scope.layout.isGridOn = false,
        $scope.layout.areProfileGroupsHidden = true;

        $scope.metadata = {}
        $scope.userId = $scope.displayUserId || CurrentUser.userId;
    };

    $scope.loadGroupsList = function () {
        initVariables();
        
        var metadataPromise = $scope.loadGroupMetadata();
        var groupsPromise = $scope.loadGroups();

        $q.all([metadataPromise, groupsPromise]).then(function () {
        },function () {
            $log.debug("--error waiting for metadataPromise and groupsPromise---");
        }).finally(function () {
            $scope.layout.isLoading = false;
        });
    };

    $scope.loadGroupsList();
}

groupListModule.controller("groupsListBaseController", groupsListBaseController);
export default groupsListBaseController;
