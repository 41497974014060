import groupsModule from "../groupsModule";

const groupCard = {
    templateUrl: "group-card",
    bindings: {
        "group": "<"
    },
    controller: "groupCardController"
};

groupsModule.component("groupCard", groupCard);

export default groupCard;
