import { Endpoints } from 'Roblox';
import groupsModule from '../groupsModule';

function groupUtilityService($filter, urlService) {
  'ngInject';

  return {
    parseGroupId(url) {
      const regexExp = /\/groups\/(\d+)\//g;
      const match = regexExp.exec(url);
      if (match && match.length > 1) {
        return Number(match[1]);
      }
      return null;
    },

    buildGameReferralUrl(placeId) {
      const urls = urlService.getGameDetailReferralUrls();
      return $filter('formatString')(urls.groupDetail, { placeId });
    },

    profilePageUrl(userId) {
      return Endpoints
        ? Endpoints.getAbsoluteUrl(`/users/${userId}/profile`)
        : `/users/${userId}/profile`;
    }
  };
}

groupsModule.factory('groupUtilityService', groupUtilityService);
export default groupUtilityService;
