import groupListModule from "../groupListModule";

function groupsListItemController(thumbnailConstants) {
    "ngInject";

    var ctrl = this;

    var init = function () {
        ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;
        ctrl.thumbnailOptions = {
            isLazyLoading: true
        }
    };

    ctrl.$onInit = init;
}

groupListModule.controller("groupsListItemController", groupsListItemController);
export default groupsListItemController;