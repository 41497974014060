import groupsModule from '../groupsModule';

const groupResources = {
    templates: {
        groupsListBaseTemplate: "groups-list-base",
        groupsListTemplate: "groups-list",
        groupAboutTemplate: "group-about",
        groupBaseTemplate: "group-base",
        groupWallTemplate: "group-wall",
        groupCommentsTemplate: "group-comments",
        groupMembersListTemplate: "group-members-list",
        groupGamesTemplate: "group-games",
        groupStoreTemplate: "group-store",
        groupStoreItemTemplate: "group-store-item",
        GroupResultsBaseTemplate: "group-results-base",
        groupCatalogItemTemplate: "group-catalog-item",
        groupSearchBarTemplate: "group-search-bar"
    },
    modals: {
        openedClass: "modal-open-noscroll",
        exileUser: {
            templateUrl: "exile-user-modal",
            controller: "exileUserController"
        },
        leaveGroup: {
            templateUrl: "leave-group-modal",
            controller: "leaveGroupController"
        },
        reportAbuse: {
            templateUrl: "report-abuse-modal",
            controller: "reportAbuseController"
        }
    }
};

groupsModule.constant("groupResources", groupResources);

export default groupResources;