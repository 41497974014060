import groupListModule from "../groupListModule";

function groupsListController($timeout, groupsListConstants, groupsConstants, groupsListService) {
    "ngInject";

    var ctrl = this;

    ctrl.createGroupUrl = function () {
        return groupsConstants.absoluteUrls.createGroup;
    };

    ctrl.canCreateGroup = function () {
        return !ctrl.groupList || ctrl.groupList.length < ctrl.maxGroups;
    };

    var init = function () {
        ctrl.layout = groupsListConstants.layout;
        ctrl.primaryGroup = null;

        if (ctrl.groupList) {
            if (ctrl.groupList.length > 0 && ctrl.groupList[0].isPrimary) {
                ctrl.primaryGroup = ctrl.groupList[0];
                ctrl.groups = ctrl.groupList.slice(1, ctrl.groupList.length);
            } else {
                ctrl.groups = ctrl.groupList;
            }

            $timeout(function() {	
                groupsListService.buildScrollbar(groupsListConstants.groupsListSelector);	
            });
        }
    };

    ctrl.$onInit = init
    ctrl.$onChanges = init
}

groupListModule.controller("groupsListController", groupsListController);
export default groupsListController;