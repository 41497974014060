import groupsModule from "../groupsModule";

function groupCardController($filter, thumbnailConstants) {
    "ngInject";

    var ctrl = this;

    var init = function () {
        ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;
        ctrl.url = $filter("seoUrl")("groups", ctrl.group.id, ctrl.group.name);
    };

    ctrl.$onInit = init;
}

groupsModule.controller("groupCardController", groupCardController);
export default groupCardController;